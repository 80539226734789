
<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs3>
              <v-select
                v-model="filter"
                :items="filters"
                item-text="text"
                item-value="value"
                label="Search by"
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="search"
                label="Enter search string"
                clearable
              />
            </v-flex>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="refreshList()"
              >
                Search
              </v-btn>
            </v-card-actions>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :pagination.sync="pagination"
            :total-items="totalItems"
            :rows-per-page-items="[10, 25, 50, 100]"
            :expand="true"
            class="px-1 pb-1"
          >
            <template #items="props">
              <tr @click="props.expanded = !props.expanded">
                <td
                  v-if="props.item.activation"
                  class="text-xs-left"
                >
                  {{ props.item.activation }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.msisdn"
                  class="text-xs-left"
                >
                  {{ props.item.msisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.nama"
                  class="text-xs-left"
                >
                  {{ props.item.nama }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.nric"
                  class="text-xs-left"
                >
                  {{ props.item.nric }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.iccid"
                  class="text-xs-left"
                >
                  {{ props.item.iccid }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.plan"
                  class="text-xs-left"
                >
                  {{ props.item.plan }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.dealer"
                  class="text-xs-center"
                >
                  {{ props.item.dealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.status "
                  class="text-xs-center"
                >
                  {{ props.item.status }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.claimer"
                  class="text-xs-center"
                >
                  {{ props.item.claimer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'
// import Jimp from 'jimp'

const pageTitle = 'Simpack List'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widepanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      xox: null,
      error: null,
      filter: null,
      filters: [
        {
          text: 'Msisdn',
          value: 'msisdn',
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Activation',
          value: 'activation',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Name',
          value: 'nama',
        },
        {
          align: 'left',
          sortable: false,
          text: 'NRIC',
          value: 'nric',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Sim Serial',
          value: 'iccid',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Introducer',
          value: 'dealer',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Claimer',
          value: 'claimer',
        },
      ],
      items: [],
      loading: false,
      pagination: {},
      snackbar: false,
      snackbarMessage: null,
      search: null,
      searchId: null,
      source: null,
      title: pageTitle,
      totalItems: 0,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    filter: function () {
      (this.search) && this.resetList()
    },
    pagination: {
      handler () {
        this.refreshList()
      },
      deep: true,
    },
  },
  methods: {
    async getUserList () {
      const filters = this.getFilters()
      if (!filters) return { items: [], totalItems: 0 }
      this.loading = true
      const { page, rowsPerPage } = this.pagination
      const parameters = {
        page,
        rowsPerPage,
      }
      Object.assign(parameters, filters)

      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get('getUploadSimpack.php', apiParameters)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
        }
        return { items: [], totalItems: 0 }
      }
    },
    getFilters () {
      const filterBy = {}

      if (this.filter === 'msisdn' && this.search) {
        Object.assign(filterBy, { msisdn: this.search })
      }

      return this.filter && (this.search) ? filterBy : null
    },
    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getUserList().then(data => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    resetList () {
      this.items = []
      this.totalItems = 0
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
